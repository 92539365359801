import request, { ResponsePageableData } from '@/libs/request'

/** 订房订单列表 */
export const getOrderHotelList = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotelEvaluation/getOrderHotelEvaluationList', data })

/** 订房订单办理入住 */
export const editOrderHotel = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/toStay', data })
/** 商家回复 */
export const reply = (data?) => request<ResponsePageableData>({ url: 'adminapi/OrderHotelEvaluation/OrderHotelEvaluationReply', data })

/** 删除评论 */
export const delOrderHotelEvaluation = (data?) => request<ResponsePageableData>({ url: 'adminapi/OrderHotelEvaluation/delOrderHotelEvaluation', data })
