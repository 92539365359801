





import Vue from 'vue'
import SelectMixin from './select-mixin'
import { mapState } from 'vuex'

/**
 * 订餐订单状态选择器
 *
 * @event input(val) 数据变化，包括切换、选择、清空都会触发，启用多选模式后会是一个数组
 */

export default Vue.extend({
	mixins: [SelectMixin],
	props: {
		placeholder: {
			type: String,
			default: '请选择订餐订单状态',
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	watch: {
		list: {
			handler(val) {
				this.activeList = val.length ? val : this.mealGoodsState
			},
			immediate: true,
		},
	},
	data() {
		return {
			activeList: [],
		}
	},
	computed: {
		...mapState<any>('order', {
			mealGoodsState: (state) => state.mealGoodsState,
		}),
	},
})
