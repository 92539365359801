














































































































































import Vue from 'vue'
import { cloneDeep } from 'lodash'
import SelectMealGoodsOrderState from '@/components/select/select-meal-goods-order.vue'
import { useTable } from '@/components/table/table-hook'
import { getMealGoodsOrderList, editMealGoodsOrder, addOrderCatering, getOrderList, cancelOrder } from '@/api/meal-goods-order'
import SelectHotel from '@/components/select/select-hotel.vue' //酒店选择器
import DialogSubmit from '@/components/form/dialog-submit.vue' //酒店选择器
import { export_json_to_excel } from '@/vendor/Export2Excel'
import { mapState } from 'vuex'
export default Vue.extend({
	components: { SelectMealGoodsOrderState, SelectHotel, DialogSubmit },
	data() {
		return {
			dialogVisible: false,
			editMealGoodsOrder,
			...useTable({
				name: '订餐订单',
				formdata: {
					id: 0,
					state: undefined,
				},
				query: {},
				getData: (query) => {
					let n_query = cloneDeep(query)
					if (n_query['datetimes']) {
						n_query['start_pay_time'] = n_query['datetimes'][0]
						n_query['end_pay_time'] = n_query['datetimes'][1]
					} else if (n_query['datetimes'] == null) {
						n_query['datetimes'] = []
						n_query['start_pay_time'] = ''
						n_query['end_pay_time'] = ''
						n_query['is_export'] = 0
					}
					return getOrderList(n_query).then((res) => res.data)
				},
				canCelData: (row) => cancelOrder({ order_id: row.id }),
			}),
		}
	},

	methods: {
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) => filterVal.map((j) => v[j]))
		},
		inportexcel() {
			this.dialogVisible = true
		},
		que() {
			setTimeout(() => {
				this.table_query.is_export = 0
			}, 500)
			this.dialogVisible = false
			this.table_query.is_export = 1
			if (this.table_query['datetimes']) {
				this.table_query['start_pay_time'] = this.table_query['datetimes'][0]
				this.table_query['end_pay_time'] = this.table_query['datetimes'][1]
			} else if (this.table_query['datetimes'] == null) {
				this.table_query['start_pay_time'] = ''
				this.table_query['end_pay_time'] = ''
			}
			getOrderList(this.table_query).then((res) => {
				this.export_data = res.data.rows
				this.export_data.forEach((it) => {
					if (it.hotel) {
						it.hotel_name = it.hotel.name
					}
					if (it.user) {
						it.nick_name = it.user.nick_name
						it.mobile = it.user.mobile
					}
					it.total_fee = it.total_fee / 100
					it.discount_fee = it.discount_fee / 100
					it.no_discount_fee = it.no_discount_fee / 100
					it.discount = it.discount / 100
					it.balance = it.balance / 100
					it.coupon_price = it.coupon_price / 100
					it.pay_fee = it.pay_fee / 100
					if (it.user) {
						if (it.user.vip_key == 0) {
							it.vip_name = '银卡会员'
						} else if (it.user.vip_key == 1) {
							it.vip_name = '金卡会员'
						} else if (it.user.vip_key == 2) {
							it.vip_name = '铂金会员'
						} else if (it.user.vip_key == 3) {
							it.vip_name = '钻石会员'
						} else if (it.user.vip_key == 4) {
							it.vip_name = '永久钻石会员'
						} else {
							it.vip_name = '-'
						}
					} else {
						it.vip_name = '-'
					}
					if (it.state == 1) {
						it.state = '待支付'
					} else if (it.state == 2) {
						it.state = '已支付'
					} else if (it.state == 3) {
						it.state = '已取消'
					} else {
						it.state = '-'
					}
				})
				//兼容ie10哦！
				require.ensure([], () => {
					const tHeader = [
						'店名',
						'订单号',
						'桌号',
						'会员名',
						'会员等级',
						'用户手机号',
						'总金额',
						'可打折',
						'不可打折',
						'折扣',
						'余额支付',
						'优惠券支付',
						'实付',
						'支付时间',
						'状态',
					] //将对应的属性名转换成中文
					// const tHeader = [];
					const filterVal = [
						'hotel_name',
						'out_trade_no',
						'table_number',
						'nick_name',
						'vip_name',
						'mobile',
						'total_fee',
						'discount_fee',
						'no_discount_fee',
						'discount',
						'balance',
						'coupon_price',
						'pay_fee',
						'pay_time',
						'state',
					] //table表格中对应的属性名
					const list = this.export_data
					const data = this.formatJson(filterVal, list)
					this.is_export = 0
					export_json_to_excel(tHeader, data, '订餐订单')
				})
			})
		},
		edit(row) {
			;(this.$refs.formDialogRef as any).open({ id: row.id, state: undefined })
		},
		onSubmit(row) {
			if (!row.table_number) {
				this.$message.error('请输入桌号')
				return
			}
			if (!row.discount_fee) {
				this.$message.error('请输入打折金额')
				return
			}
			if (!row.no_discount_fee) {
				this.$message.error('请输入不打折金额')
				return
			}
			if (!row.total_fee) {
				this.$message.error('请输入总金额')
				return
			}
			row.discount_fee = row.discount_fee * 100
			row.no_discount_fee = row.no_discount_fee * 100
			row.total_fee = row.total_fee * 100
			if (row.discount_fee + row.no_discount_fee == row.total_fee) {
				return addOrderCatering(row).then(() => this.$message.success('保存成功') && this.tableGetData())
			} else if (row.discount_fee + row.no_discount_fee < row.total_fee) {
				this.$message.error('打折金额和不打折金额相加小于总金额，请重新输入')
				return false
			} else if (row.discount_fee + row.no_discount_fee > row.total_fee) {
				this.$message.error('打折金额和不打折金额相加大于总金额，请重新输入')
				return false
			}
		},
	},
	created() {
		this.tableGetData()
	},
	computed: {
		...mapState<any>('order', {
			mealGoodsState: (state) => state.mealGoodsState,
		}),
	},
})
