import request, { ResponsePageableData } from '@/libs/request'

/** 订房订单列表 */
export const getOrderHotelList = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/getOrderHotelList', data })

/** 订房订单办理入住 */
export const editOrderHotel = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/toStay', data })

/** 订房订单列表支付总金额 */
export const getdataList = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/getOrderHotelListCount', data })

/** 取消订单 */
export const cancelOrder = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/cancelOrder', data })

/** 订单退款检测 */
export const checkOrder = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/checkOrder', data })

/** 订单退款 */
export const refundOrder = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/refundOrder', data })

/** 订单确认退款数据 */
export const getOrderRefundInfo = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/getOrderRefundInfo', data })

/** 订单确认退款 */
export const refundSubmit = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/refundSubmit', data })
// 订单转移

export const getRoomTypeList = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/getRoomTypeList', data })
// 订单转移确认

export const moveOrderToMicWin = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/moveOrderToMicWin', data })

// 订单房间信息
export const getOrderHotelStays = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderHotel/getOrderHotelStays', data })
// 早餐订单取消
export const cancel = (data?) => request<ResponsePageableData>({ url: '/adminapi/orderBreakfast/cancel', data })
// 早餐订单退款详情
export const refundDetail = (data?) => request<ResponsePageableData>({ url: '/adminapi/orderBreakfast/refundDetail', data })
// 早餐订单退款
export const refundOrders = (data?) => request<ResponsePageableData>({ url: '/adminapi/orderBreakfast/refundOrder', data })
export const detail = (data?) => request<ResponsePageableData>({ url: '/adminapi/orderBreakfast/detail', data })
