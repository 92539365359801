import request, { ResponsePageableData } from '@/libs/request'

/** 订餐订单列表 */
export const getMealGoodsOrderList = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderRestaurant/getOrderList', data })

/** 设置订单状态（订单列表增加两个按钮（制作完成，配送完成）） */
export const editMealGoodsOrder = (data?) => request<ResponsePageableData>({ url: '/adminapi/OrderRestaurant/setState', data })

/** 添加订餐订单 */
export const addOrderCatering = (data?) => request<ResponsePageableData>({ url: '/adminapi/orderCatering/add ', data })

/** 获取订餐订单 */
export const getOrderList = (data?) => request<ResponsePageableData>({ url: '/adminapi/orderCatering/getOrderList ', data })

/** 取消订餐订单 */
export const cancelOrder = (data?) => request<ResponsePageableData>({ url: '/adminapi/orderCatering/cancelOrder ', data })
